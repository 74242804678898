<div class="box container"  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20">
    <p class="question-number">{{'0'+question.questionNumber}}</p>
    <div class="question" [innerHTML]="question.questionDescription[lang]"></div>
    <br>
    <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="0" *ngIf="answer">
        <!-- Sad smiley face -->
        <svg *ngIf="answer === 'POOR'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="60px" fill="var(--primary-font-color)" (click)="onAnswer('POOR')">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480 C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle  cx="176" cy="176" r="32"/>
                <circle  cx="336" cy="176" r="32"/>
                <path  d="M256,240c-79.529,0-144,64.471-144,144h32c0-61.856,50.144-112,112-112s112,50.144,112,112h32 C400,304.471,335.529,240,256,240z"/>
            </g>
        </svg>
        
        <!-- thinking smiley face -->
        <svg *ngIf="answer === 'NOT_GOOD'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="60px" fill="var(--primary-font-color)" (click)="onAnswer('NOT_GOOD')" >
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M208,384h32c0-44.183,35.817-80,80-80v-32C258.144,272,208,322.144,208,384z"/>
            </g>
        </svg>
        
        <!-- Sceptic smiley face -->
        <svg *ngIf="answer === 'OK'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="60px" fill="var(--primary-font-color)" (click)="onAnswer('OK')">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <rect x="144" y="304" width="224" height="32"/>
            </g>
        </svg>

        <!-- Happy smiley face -->
        <svg *ngIf="answer === 'GOOD'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="60px" fill="var(--primary-font-color)" (click)="onAnswer('GOOD')">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M208,352v32c61.856,0,112-50.144,112-112h-32C288,316.183,252.183,352,208,352z"/>
            </g>
        </svg>

        <!-- Very happy smiley face -->
        <svg *ngIf="answer === 'EXCELLENT'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="60px" fill="var(--primary-font-color)" (click)="onAnswer('EXCELLENT')">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480    C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M368,256c0,61.856-50.144,112-112,112s-112-50.144-112-112h-32c0,79.529,64.471,144,144,144s144-64.471,144-144H368z"/>
            </g>
        </svg>
        <p>{{getAnswerLiteral(answer)}}</p>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20">
        <!-- Sad smiley face -->
        <svg class="smiley-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="35px" fill="var(--primary-font-color)" (click)="onAnswer('POOR')" [ngStyle]="{'opacity': ((!answer || answer === 'POOR') ? 1.0 : 0.2)}">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480 C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle  cx="176" cy="176" r="32"/>
                <circle  cx="336" cy="176" r="32"/>
                <path  d="M256,240c-79.529,0-144,64.471-144,144h32c0-61.856,50.144-112,112-112s112,50.144,112,112h32 C400,304.471,335.529,240,256,240z"/>
            </g>
        </svg>
        
        <!-- thinking smiley face -->
        <svg class="smiley-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="35px" fill="var(--primary-font-color)" (click)="onAnswer('NOT_GOOD')" [ngStyle]="{'opacity': ((!answer || answer === 'NOT_GOOD') ? 1.0 : 0.2)}">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M208,384h32c0-44.183,35.817-80,80-80v-32C258.144,272,208,322.144,208,384z"/>
            </g>
        </svg>
        
        <!-- Sceptic smiley face -->
        <svg class="smiley-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="35px" fill="var(--primary-font-color)" (click)="onAnswer('OK')" [ngStyle]="{'opacity': ((!answer || answer === 'OK') ? 1.0 : 0.2)}">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <rect x="144" y="304" width="224" height="32"/>
            </g>
        </svg>

        <!-- Happy smiley face -->
        <svg class="smiley-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="35px" fill="var(--primary-font-color)" (click)="onAnswer('GOOD')" [ngStyle]="{'opacity': ((!answer || answer === 'GOOD') ? 1.0 : 0.2)}">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M208,352v32c61.856,0,112-50.144,112-112h-32C288,316.183,252.183,352,208,352z"/>
            </g>
        </svg>

        <!-- Very happy smiley face -->
        <svg class="smiley-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="35px" fill="var(--primary-font-color)" (click)="onAnswer('EXCELLENT')" [ngStyle]="{'opacity': ((!answer || answer === 'EXCELLENT') ? 1.0 : 0.2)}">
            <g>
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480    C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
                <circle cx="176" cy="176" r="32"/>
                <circle cx="336" cy="176" r="32"/>
                <path d="M368,256c0,61.856-50.144,112-112,112s-112-50.144-112-112h-32c0,79.529,64.471,144,144,144s144-64.471,144-144H368z"/>
            </g>
        </svg>
    </div>
</div>