import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StartingPageComponent } from './view/pages/starting-page/starting-page.component';
import { CsiQuestionnaireComponent } from './view/pages/csi-questionnaire/csi-questionnaire.component';
import { TokenValidGuard } from './guards/token-valid/token-valid.guard';
import { ExpiredPageComponent } from './view/pages/expired-page/expired-page.component';
import { SuccessPageComponent } from './view/pages/success-page/success-page.component';
import { PostedAnswersGuard } from './guards/posted-answers/posted-answers.guard';
import { HasErrorGuard } from './guards/has-error/has-error.guard';
import { NegatePostedAnswersGuard } from './guards/negate-posted-answers/negate-posted-answers.guard';
import { ErrorPageComponent } from './view/pages/error-page/error-page.component';

const routes: Routes = [
  {path: 'csi-questionaire', component: CsiQuestionnaireComponent, canActivate: [TokenValidGuard, NegatePostedAnswersGuard],
    data: {
      redirectTo: '/expired',
    }
  },
  {path: 'success', component: SuccessPageComponent, canActivate: [TokenValidGuard, PostedAnswersGuard], 
    data: {
      redirectTo: '/',
    }
  },
  {path: 'error', component: ErrorPageComponent, canActivate: [TokenValidGuard, HasErrorGuard],
    data: {
      redirectTo: '/',
    }
  },
  {path: 'expired', component: ExpiredPageComponent},
  {path: '**', component: StartingPageComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    TokenValidGuard,
    PostedAnswersGuard,
    NegatePostedAnswersGuard,
    HasErrorGuard
  ]
})
export class AppRoutingModule { }
