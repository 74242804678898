<div class="box container" fxLayout="column" fxLayoutAlign="center center">
    <p class="question-number">{{'0'+question.questionNumber}}</p>
    <div class="question" [innerHTML]="question.questionDescription[lang]"></div>
    <br>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20">
        <button  *ngFor="let i of question.answers"
                type="button"
                class="numeric-button"
                [ngStyle]="{'opacity': (answer === -9999999999 || i === answer) ? 1.0 : 0.2}"
                (click)="onNumericButtonClick(i)">{{i}}</button>
    </div>
</div>