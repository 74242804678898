<div *ngIf="tokenIsValid" class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="35px">
    <h2>Opinioni juaj është i rëndësishëm për ne.</h2>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h3>I dashur anëtar</h3>
        <p class="start-page-description">Referuar eksperiencës suaj të fundit në dyqan,<br> 
dëshirojmë të marrim vlerësimin tuaj në<br> 
mënyrë që të përmirësohemi herën tjetër. <br>
Nevojitet 1’ nga koha juaj për t’iu përgjigjur 4 <br>
pyetjeve të thjeshta.<br>
Faleminderit për kohën tuaj!</p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <button id="letsStartBtn" type="button" (click)="onLetsStart()" style="font-size: 20px;">Shtyp <span class="strong-words">këtu</span> për të filluar</button>
    </div>
    <p class="start-page-description">Pyetësori do të jetë i disponueshëm për 14 ditë</p>
</div>