import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PostedAnswersGuard } from '../posted-answers/posted-answers.guard';

@Injectable({
  providedIn: 'root'
})
export class NegatePostedAnswersGuard implements CanActivate {
  constructor(private postedAnswersGuard: PostedAnswersGuard, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasPostedAnswers = this.postedAnswersGuard.canActivate(route, state);
    if(hasPostedAnswers && route.data['redirectTo']){
      this.router.navigate([route.data['redirectTo']]);
    }
      
    return !hasPostedAnswers;
  }
}
