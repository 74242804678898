import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { SmileySurveyQuestionComponent } from './view/components/smiley-survey-question/smiley-survey-question.component';
import { NumericSurveyQuestionComponent } from './view/components/numeric-survey-question/numeric-survey-question.component';
import { CsiQuestionnaireComponent } from './view/pages/csi-questionnaire/csi-questionnaire.component';
import { StartingPageComponent } from './view/pages/starting-page/starting-page.component';
import { ExpiredPageComponent } from './view/pages/expired-page/expired-page.component';
import { SuccessPageComponent } from './view/pages/success-page/success-page.component';
import { ErrorPageComponent } from './view/pages/error-page/error-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SmileySurveyQuestionComponent,
    NumericSurveyQuestionComponent,
    CsiQuestionnaireComponent,
    StartingPageComponent,
    ExpiredPageComponent,
    SuccessPageComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
