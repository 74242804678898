import { Component, OnInit } from '@angular/core';
import { CsiAnswer } from '../../../models/csi.model';
import { CsiService } from '../../../services/csi-service/csi.service';
import { Router } from '@angular/router';
import { SmileyQuestion } from '../../../models/smiley-question.model';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NumericQuestion } from '../../../models/numeric-question.model';

@Component({
  selector: 'app-csi-questionnaire',
  templateUrl: './csi-questionnaire.component.html',
  styleUrls: ['./csi-questionnaire.component.css']
})
export class CsiQuestionnaireComponent implements OnInit {
  submitting = false;
  smileyQ: SmileyQuestion[] = [
    {
      questionNumber: 1,
      questionDescription: {
        'el': 'Πως θα <span class="strong-words">βαθμολογούσες</span> την <span class="strong-words">προϊοντική πληρότητα</span> του καταστήματος σε σχέση με τις δικές σου <span class="strong-words">προτιμήσεις & ανάγκες</span>?',
        'en': 'How would you <span class="strong-words">rate</span> the quality of the <span class="strong-words">products purchased</span> compared to the <span class="strong-words">price paid</span>?',
        'al': 'Si do ta vlerësonit cilësinë e shërbimit që keni marrë në dyqan?'
      },
      answers: {
        'el':{
          POOR: 'ΠΟΛΥ ΚΑΚΗ',
          NOT_GOOD: 'ΚΑΚΗ',
          OK: 'ΜΕΤΡΙΑ',
          GOOD:'ΙΚΑΝΟΠΟΙΗΤΙΚΗ',
          EXCELLENT:'ΕΞΑΙΡΕΤΙΚΗ'
        },
        'en':{
          POOR: 'VERY BAD',
          NOT_GOOD: 'BAD',
          OK: 'OK',
          GOOD:'GOOD',
          EXCELLENT:'EXCELLENT'
        },
        'al':{
          POOR:'SHUMË KEQ',
                NOT_GOOD: 'KEQ',
                OK: 'MIRË',
                GOOD: 'SHUMË MIRË',
                EXCELLENT: 'SHKËLQYER'
        }
      }
    },
    {
      questionNumber: 2,
      questionDescription: {
        'el':'Πως θα <span class="strong-words">βαθμολογούσες</span> την ποιότητα της <span class="strong-words">εξυπηρέτησής</span> σου κατά την παραμονή σου στα καταστήματα μας?',
        'en': 'How would you <span class="strong-words">rate</span> the quality of <span class="strong-words">customer service</span> you received?',
        'al': 'Në përgjithësi, sa të kënaqur jeni me produktet e blera?'
            },
      answers: {
        'el':{
          POOR: 'ΠΟΛΥ ΚΑΚΗ',
          NOT_GOOD: 'ΚΑΚΗ',
          OK: 'ΜΕΤΡΙΑ',
          GOOD:'ΙΚΑΝΟΠΟΙΗΤΙΚΗ',
          EXCELLENT:'ΕΞΑΙΡΕΤΙΚΗ'
        },
        'en':{
          POOR: 'VERY BAD',
          NOT_GOOD: 'BAD',
          OK: 'OK',
          GOOD:'GOOD',
          EXCELLENT:'EXCELLENT'
        },
        'al':{
          POOR:'SHUMË KEQ',
          NOT_GOOD: 'KEQ',
          OK: 'MIRË',
          GOOD: 'SHUMË MIRË',
          EXCELLENT: 'SHKËLQYER'
        }
      }
    },
    {
      questionNumber: 3,
      questionDescription: {
        'el': 'Πως θα <span class="strong-words">αξιολογούσες</span> την γενική σου <span class="strong-words">εμπειρία</span> στο κατάστημα (μουσική, κλιματισμός, φωτισμός, καθαριότητα)',
        'en': 'Overall, how <span class="strong-words">satisfied</span> are you with the <span class="strong-words">purchased product</span>?',
        'al': 'Si do ta vlerësonit cilësinë e produkteve të blera në krahasim me çmimin e paguar?'
      },
      answers: {
        'el':{
          POOR: 'ΠΟΛΥ ΚΑΚΗ',
          NOT_GOOD: 'ΚΑΚΗ',
          OK: 'ΜΕΤΡΙΑ',
          GOOD:'ΙΚΑΝΟΠΟΙΗΤΙΚΗ',
          EXCELLENT:'ΕΞΑΙΡΕΤΙΚΗ'
        },
        'en':{
          POOR: 'NOT AT ALL',
          NOT_GOOD: 'A LITTLE',
          OK: 'OK',
          GOOD:'SATISFACTORILY',
          EXCELLENT:'EXCELLENT'
        },
        'al':{
          POOR:'SHUMË KEQ',
                NOT_GOOD: 'KEQ',
                OK: 'MIRË',
                GOOD: 'SHUMË MIRË',
                EXCELLENT: 'SHKËLQYER'
        }
      }
    }
  ];

  numericQ: NumericQuestion[] = [];

  netPromoterScoreQ: NumericQuestion = {
    questionNumber: 4,
    questionDescription: {
      'el': 'Με κλίμακα 1-10, θα <span class="strong-words">πρότεινες</span> τα καταστήματα μας (ZAKCRET SPORTS, SNEAKERCAGE & ZAKCRET OUTLET CENTER) σε <span class="strong-words">κάποιον φίλο σου</span>?',
      'en': 'Με κλίμακα 1-10, πόσο θα <span class="strong-words">πρότεινες</span> τα καταστήματα μας (ZAKCRET SPORTS, SNEAKERCAGE & ZAKCRET OUTLET CENTER) σε <span class="strong-words">κάποιον φίλο σου</span>?',
      'al': 'Në një shkallë nga 1 deri në 10, sa do t’ia rekomandonit një shoku?'
    },
    answers: [1,2,3,4,5,6,7,8,9,10]
  }
  
  smileyQuestions: FormArray = this.formBuilder.array([]);
  numericQuestions: FormArray = this.formBuilder.array([]);
  netPromoterScore: FormArray = this.formBuilder.array([]);
  csiForm: FormGroup = this.formBuilder.group({
    smileyQuestions: this.smileyQuestions,
    numericQuestions: this.numericQuestions,
    netPromoterScore: this.netPromoterScore
  });

  constructor(private csiService: CsiService,
              private router: Router,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    sessionStorage.removeItem('hasError');

    this.createSmileyQuestionsForm();
    this.createNumericQuestionsForm();
    this.createNetPromoterScoreForm();
  }

  onSubmit(): void{
    if(!this.csiForm.valid){
      return;
    }

    this.submitting = true;
    this.csiService.postAnswers(this.getCsiAnswers(), 
                                this.getNetPromoterScore(),
                                sessionStorage.getItem('token')!)
      .subscribe(
        success => {
          this.submitting = false;
          sessionStorage.setItem('postedAnswers', 'true');
          this.goToSuccessPage();
        },
        error => {
          this.submitting = false;
          if(error.status === 400 && error.error === 'Form has been already submmitted'){
            sessionStorage.removeItem('tokenIsValid');
            this.goToExiredPage();
            return;
          }
          
          this.submitting = false;
          sessionStorage.setItem('hasError', 'true');
          this.goToErrorPage();
        }
      );
  }

  private createSmileyQuestionsForm(): void {
    this.smileyQuestions = this.csiForm.get('smileyQuestions') as FormArray;
    for(let q of this.smileyQ){
      this.smileyQuestions.push(new FormControl('', Validators.required));
    }
  }

  private createNumericQuestionsForm(): void {
    this.numericQuestions = this.csiForm.get('numericQuestions') as FormArray;
    for(let q of this.numericQ){
      this.numericQuestions.push(new FormControl('', Validators.required));
    }
  }

  private createNetPromoterScoreForm(): void {
    this.netPromoterScore = this.csiForm.get('netPromoterScore') as FormArray;
    this.netPromoterScore.push(new FormControl('', Validators.required));
  }

  private getCsiAnswers(): CsiAnswer[] {
    const answers: CsiAnswer[] = [];
    let i=0;

    this.smileyQuestions.value.forEach((v:any) => {
      answers.push({
        questionNumber: ++i, 
        answer: v
      });
    });

    this.numericQuestions.value.forEach((v:any) => {
      answers.push({
        questionNumber: ++i, 
        answer: v
      });
    });

    return answers;
  }

  private getNetPromoterScore(): number {
    return this.netPromoterScore.value[0];
  }

  private goToSuccessPage(): void {
    this.router.navigate(['success']);
  }
  private goToErrorPage(): void {
    this.router.navigate(['error']);
  }
  private goToExiredPage(): void {
    this.router.navigate(['expired']);
  }
}
