<br>
<div fxLayout="row" fxLayoutAlign="center center">
  <img src="./assets/images/logos/happy.svg" class="main-logo">
</div>
<!-- <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="15px">
  <img src="./assets/images/logos/happy_only.svg" class="store-logo">
</div> -->
<br>

<router-outlet></router-outlet>

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
  <a href="https://www.happy.al">www.happy.al</a>
</div>