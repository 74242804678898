<form fxLayout="column" fxLayoutAlign="center center" [formGroup]="csiForm" (ngSubmit)="onSubmit()">
    <div  fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40">
        <div formArrayName="smileyQuestions" *ngFor="let item of smileyQuestions.controls; let i = index;">
            <div [formGroupName]="i">
                <app-smiley-survey-question 
                    [question]="smileyQ[i]"
                    [formControl]="$any(smileyQuestions).controls[i]">
                </app-smiley-survey-question>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" >
        <div formArrayName="numericQuestions" *ngFor="let item of numericQuestions.controls; let j = index;">
            <div [formGroupName]="j">
                <app-numeric-survey-question
                    [question]="numericQ[j]"
                    [formControl]="$any(numericQuestions).controls[j]">
                </app-numeric-survey-question>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" >
        <div formArrayName="netPromoterScore">
            <div [formGroupName]="0">
                <app-numeric-survey-question
                    [question]="netPromoterScoreQ"
                    [formControl]="$any(netPromoterScore).controls[0]">
                </app-numeric-survey-question>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
        <button type="submit" [disabled]="submitting || !csiForm.valid" ><span class="strong-words" style="font-size: 20px;">DËRGO</span></button>
    </div>
    <br>
</form>