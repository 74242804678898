import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumericQuestion } from '../../../models/numeric-question.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-numeric-survey-question',
  templateUrl: './numeric-survey-question.component.html',
  styleUrls: ['./numeric-survey-question.component.css'], providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericSurveyQuestionComponent),
      multi: true
    }
  ]
})
export class NumericSurveyQuestionComponent implements OnInit, ControlValueAccessor {

  lang = environment.lang;

  @Input() question: NumericQuestion = {
    questionNumber: 0,
    questionDescription:{
      'el': 'ΕΡΩΤΗΣΗ',
      'al': 'Pyetje'
    }, 
    answers:[]
  };

  answer: number = -9999999999;
  disabled = false;
  value = 0;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  onNumericButtonClick(i: number): void {
    this.answer = i;
    this.value = i;
    this.onChange(this.value);
  }

  
  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
