import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { csiAnswerRequest, defaultResponse } from 'src/app/models/csi-http.model';
import { CsiAnswer } from '../../models/csi.model';
@Injectable({
  providedIn: 'root'
})
export class CsiService {

  constructor(private http: HttpClient) { }

  validateToken(token: string): Observable<any> {
    const url = `${environment.apiURL}/rest/public/customer-experience/validation?token=${token}`;
    return this.http.get<any>(url);
  }

  postAnswers(answers: any, nps: number,  token: string): Observable<defaultResponse<string>> {
    const url = `${environment.apiURL}/rest/public/customer-experience/invoice?token=${token}&formId=${environment.formId}&nps=${nps}`;
    return this.http.post<defaultResponse<string>>(url, answers);
  }
}
