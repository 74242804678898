import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CsiService } from '../../../services/csi-service/csi.service';
import { TokenValidGuard } from '../../../guards/token-valid/token-valid.guard';

@Component({
  selector: 'app-starting-page',
  templateUrl: './starting-page.component.html',
  styleUrls: ['./starting-page.component.css']
})
export class StartingPageComponent implements OnInit {
  tokenIsValid = false;

  constructor(private router: Router, private route: ActivatedRoute, private csiService:CsiService) {
    this.route.queryParams.subscribe(params => {
 
      if(sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined' && sessionStorage.getItem('tokenIsValid')){
        this.tokenIsValid = true;
        return;
      }

      const token = params.token;
      if(!token){
        this.goToExiredPage();
        return;
      }

      sessionStorage.setItem('token',  token);
      
      this.csiService.validateToken(token).subscribe(success => {
       sessionStorage.setItem('tokenIsValid', 'true');
       this.tokenIsValid = true;
      }, 
      error => {
        sessionStorage.removeItem('tokenIsValid');
        this.goToExiredPage();
      });
    });
   }

  ngOnInit(): void {
    sessionStorage.removeItem('postedAnswers');
  }

  onLetsStart(): void {
    this.router.navigate(['/csi-questionaire'])
  }

  private goToExiredPage(): void {
    this.router.navigate(['expired']);
  }
}
