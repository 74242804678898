import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenValidGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tokenIsValid = sessionStorage.getItem('tokenIsValid') === 'true';
    if(!tokenIsValid && route.data['redirectTo']){
      this.router.navigate([route.data['redirectTo']]);
    }
    return sessionStorage.getItem('tokenIsValid') === 'true';
  }
}
